import React from "react";

function Footer() {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        
        backgroundColor: "white",
        textAlign: "center",
      }}
    >
      <p style={{ color: "#8c8989", fontSize: 15, fontWeight: 500 }}>
        Fleur de Lys | official website | Développé par{" "}
        <a href="https://fidness.net/#/" style={{ color: "#4F4F4F" }}>
          Fidness
        </a>
      </p>
    </div>
  );
}

export default Footer;






import {
  UPDATE_CAROUSSELONGLERIE,
  CAROUSSELONGLERIE_ERROR
} from '../actions/types';

const initialState = {
 carousselonglerie: null,
  loading: true,
  error: {}
};
function carousselOnglerieReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    
    case   UPDATE_CAROUSSELONGLERIE:
      return {
        ...state,
        carousselonglerie: payload,
        loading: false
      };
    
    case   CAROUSSELONGLERIE_ERROR :
      return {
        ...state,
        error: payload,
        loading: false,
        carousselonglerie: null
      };
    
    default:
      return state;
  }
}

export default carousselOnglerieReducer;

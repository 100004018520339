import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getNosMarieesCaroussel,
  deleteNosMarieesCaroussel,
} from '../../actions/nosMarieesCaroussel';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const NosMarieesCaroussel = ({
  getNosMarieesCaroussel,
  deleteNosMarieesCaroussel,
  nosMarieesCaroussel: { nosmarieescaroussels },
}) => {
  useEffect(() => {
    getNosMarieesCaroussel();
  }, [getNosMarieesCaroussel]);

  const [page, setPage] = useState(1);

  const selectPageHandle = (selectedPage) => {
    // Pagination Logic
    if (
      selectedPage >= 1 &&
      selectedPage <= nosmarieescaroussels &&
      nosmarieescaroussels.length / 1 &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  return (
    <div className='container-table2 '>
      <div className='main-table2'>
        <div className='userTable '>
          <h1 className='heading'>La liste de nos mariées :</h1>
          <Link to='/nosmarieescarousselform'>
            <button className='contactCTA contact ' type='button'>
              ajouter{' '}
            </button>
          </Link>

          <table className='table2'>
            <tbody>
              <tr>
                <th className='userAddress'>titre</th>

                <th className='userAction'>Delete</th>
              </tr>

              {nosmarieescaroussels.length > 0 ? (
                nosmarieescaroussels
                  .slice(page * 5 - 5, page * 5)
                  .map((nosmarieescaroussel, index) => {
                    return (
                      <tr>
                        <td className='userAddress f-weight'>
                          {nosmarieescaroussel.titre}
                        </td>

                        <td className='contact'>
                          <button
                            className='contactCTA'
                            onClick={() =>
                              deleteNosMarieesCaroussel(nosmarieescaroussel._id)
                            }
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <td colSpan='7' className='no-results'>
                    il n'y a pas d'article ici.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* JSX PArt */}
          {nosmarieescaroussels.length > 0 && (
            <div className='bapagination'>
              <div
                className='arrows'
                onClick={() => selectPageHandle(page - 1)}
              >
                <MdKeyboardArrowLeft size={25} />
              </div>
              <div className='pageNumbers'>
              {
                   [...Array(Math.ceil(nosmarieescaroussels.length / 5))].map((n, i) => {
                      return <div  
                      className={`num ${page === i + 1 ? `numActive` : ''}`} 
                      onClick={() => selectPageHandle(i + 1)} >{i + 1}</div>
                  })
              }
          </div>
              <div
                className='arrows'
                onClick={() => selectPageHandle(page + 1)}
              >
                <MdKeyboardArrowRight size={25} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

NosMarieesCaroussel.propTypes = {
  getNosMarieesCaroussel: PropTypes.func.isRequired,
  deleteNosMarieesCaroussel: PropTypes.func.isRequired,
  nosMarieesCaroussel: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  nosMarieesCaroussel: state.nosMarieesCaroussel,
});
export default connect(mapStateToProps, {
  getNosMarieesCaroussel,
  deleteNosMarieesCaroussel,
})(NosMarieesCaroussel);

import {
  UPDATE_VIDEONOSMARIEES,
  VIDEONOSMARIEES_ERROR
} from '../actions/types';

const initialState = {
  videonosmariees: null,
  loading: true,
  error: {}
};
function videoNosMarieesReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    
    case UPDATE_VIDEONOSMARIEES:
      return {
        ...state,
        videonosmariees: payload,
        loading: false
      };
    
    case   VIDEONOSMARIEES_ERROR
    :
      return {
        ...state,
        error: payload,
        loading: false,
        videonosmariees: null
      };
    
    default:
      return state;
  }
}

export default videoNosMarieesReducer;

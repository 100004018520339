import {
  UPDATE_ACTUALITES,
  ACTUALITES_ERROR,
  DELETE_ACTUALITES,
  GET_ACTUALITES
} from '../actions/types';

const initialState = {
  actualites: [],
  actualite: null,
  loading: true,
  error: {}
};
function actualitesReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    
    case UPDATE_ACTUALITES:
      return {
        ...state,
        actualites: [payload, ...state.actualites],
        loading: false
      };
      case GET_ACTUALITES:
      return {
        ...state,
        actualites: payload,
        loading: false
      };
      case DELETE_ACTUALITES:
      return {
        ...state,
        actualites: state.actualites.filter((actualite) => actualite._id !== payload),
        loading: false,
        error: null,
      };
    
    
    case   ACTUALITES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        actualites: null
      };
    
    default:
      return state;
  }
}

export default actualitesReducer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createVideoNosMariees  } from '../../actions/videoNosMariees';

const VideoNosMariees = ({createVideoNosMariees}) => {
  const [formValues, setFormValues] = useState({
    text: '',
   
    
    video: null,
   
  });
  
  const handleFieldChange = (fieldName, value) => {
    setFormValues(prevValues => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };
  
  async function handleOnSubmit(e) {
    e.preventDefault();
  
    const formData = new FormData();
    for (const fieldName in formValues) {
      if (fieldName.startsWith("video ")) {
        if (formValues[fieldName]) {
          formData.append(fieldName, formValues[fieldName]);
        }
      } else {
        formData.append(fieldName, formValues[fieldName]);
      }
    }
  
    await createVideoNosMariees(formData);
  
    // Clear form values
    setFormValues({
      text: '',
     
      video: null,
    });
  }

  

  return (
    <div className='container-trainings'>
    <div className='main'>
   
    <div className="details">
    
        
      <div className="table">
        <div className="Header">
         
          <h2>Modifier votre video et description ici </h2>
          <small>* = required field</small>
        </div>
        <div className="internship">
        <form
      className="form"  encType='multipart/form-data' onSubmit={handleOnSubmit}>
            <div className='form-group'>
                <textarea
                    id="text"
                    name="text"
                    placeholder="* description"
                    value={formValues.text}
                    onChange={e => handleFieldChange('text', e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
        <label htmlFor="video-upload">Upload video :</label>
            <input
              type="file"
              placeholder="* video 1"
              name="video"  
              accept="video/*"
              id="video-upload"
              className='custom-file-input-resume'
              onChange={e => handleFieldChange('video', e.target.files[0])}


              required         
            />                   
          </div>
   
           
          <input type="submit" className="internshipBtn" value="MODIFIER" />
         
        </form>
        </div>
      </div>
      </div>
    
   </div>
   </div>
     
  )
}
VideoNosMariees.propTypes = {
  createVideoNosMariees:PropTypes.func.isRequired,
}

export default connect(null,{createVideoNosMariees})(VideoNosMariees)

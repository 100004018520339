export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT= 'LOGOUT';
export const UPDATE_INFORMATIONACCUEIL='UPDATE_INFORMATIONACCUEIL';
export const INFORMATIONACCUEIL_ERROR ='INFORMATIONACCUEIL_ERROR';
export const UPDATE_VIDEOACCUEIL ='UPDATE_VIDEOACCUEIL';
export const  VIDEOACCUEIL_ERROR = 'VIDEOACCUEIL_ERROR';
export const UPDATE_FORFAITSCAROUSSEL ='UPDATE_FORFAITSCAROUSSEL';
export const FORFAITSCAROUSSEL_ERROR = 'FORFAITSCAROUSSEL_ERROR';
export const UPDATE_CAROUSSELACCEL ='UPDATE_CAROUSSELACCEL';
export const CAROUSSELACCEL_ERROR = 'CAROUSSELACCEL_ERROR';
export const UPDATE_CARDFORMATION = 'UPDATE_CARDFORMATION';
export const CARDFORMATION_ERROR = 'CARDFORMATION_ERROR';
export const UPDATE_FORMATIONDESCRIPTION = 'UPDATE_FORMATIONDESCRIPTION';
export const FORMATIONDESCRIPTION_ERROR = 'FORMATIONDESCRIPTION_ERROR';
export const UPDATE_FORMATIONCAROUSSEL = 'UPDATE_FORMATIONCAROUSSEL';
export const FORMATIONCAROUSSEL_ERROR = 'FORMATIONCAROUSSEL_ERROR';
export const EQUIPECAROUSSEL_ERROR = 'EQUIPECAROUSSEL_ERROR';
export const UPDATE_EQUIPECAROUSSEL = 'UPDATE_EQUIPECAROUSSEL';
export const UPDATE_IMAGEEQUIPE = 'UPDATE_IMAGEEQUIPE';
export const IMAGEEQUIPE_ERROR = 'IMAGEEQUIPE_ERROR';
export const UPDATE_ACTUALITES ='UPDATE_ACTUALITES';
export const ACTUALITES_ERROR ='ACTUALITES_ERROR';
export const UPDATE_IMAGEACTUALITES ='UPDATE_IMAGEACTUALITES';
export const IMAGEACTUALITES_ERROR ='IMAGEACTUALITES_ERROR';
export const ADD_FORFAIT ='ADD_FORFAIT';
export const FORFAIT_ERROR ='FORFAIT_ERROR';
export const DELETE_FORFAIT ='DELETE_FORFAIT';
export const GET_FORFAITS ='GET_FORFAITS';
export const CAROUSSELACCESSOIRES_ERROR ='CAROUSSELACCESSOIRES_ERROR';
export const UPDATE_CAROUSSELACCESSOIRES ='UPDATE_CAROUSSELACCESSOIRES';
export const UPDATE_CAROUSSELMIMI ='UPDATE_CAROUSSELMIMI';
export const CAROUSSELMIMI_ERROR ='CAROUSSELMIMI_ERROR';
export const UPDATE_ONGLERIE ='UPDATE_ONGLERIE';
export const ONGLERIE_ERROR = 'ONGLERIE_ERROR';
export const UPDATE_CAROUSSELHAIR='UPDATE_CAROUSSELHAIR';
export const CAROUSSELHAIR_ERROR ='CAROUSSELHAIR_ERROR';
export const UPDATE_SOINSVISAGE ='UPDATE_SOINSVISAGE';
export const SOINSVISAGE_ERROR ='SOINSVISAGE_ERROR';
export const UPDATE_SOINSCHEVEUX ='UPDATE_SOINSCHEVEUX';
export const SOINSCHEVEUX_ERROR='SOINSCHEVEUX_ERROR';
export const UPDATE_MAKEUP= 'UPDATE_MAKEUP';
export const MAKEUP_ERROR ='MAKEUP_ERROR';
export const ADD_BEFOREANDAFTER ='ADD_BEFOREANDAFTER';
export const BEFOREANDAFTER_ERROR ='BEFOREANDAFTER_ERROR';
export const DELETE_BEFOREANDAFTER ='DELETE_BEFOREANDAFTER';
export const GET_BEFOREANDAFTER ='GET_BEFOREANDAFTER';
export const ADD_BAAEXTENSION ='ADD_BAAEXTENSION';
export const BAAEXTENSION_ERROR ='BAAEXTENSION_ERROR';
export const DELETE_BAAEXTENSION ='DELETE_BAAEXTENSION';
export const GET_BAAEXTENSION ='GET_BAAEXTENSION';
export const ADD_BAACOLORATION ='ADD_BAACOLORATION';
export const BAACOLORATION_ERROR ='BAACOLORATION_ERROR';
export const DELETE_BAACOLORATION ='DELETE_BAACOLORATION';
export const GET_BAACOLORATION ='GET_BAACOLORATION';
export const ADD_COLORATION ='ADD_COLORATION';
export const COLORATION_ERROR ='COLORATION_ERROR';
export const ADD_EXTENSION ='ADD_EXTENSION';
export const EXTENSION_ERROR ='EXTENSION_ERROR';
export const PASSWORD_CHANGE_SUCCESS ='PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAIL ='PASSWORD_CHANGE_FAIL';
export const UPDATE_VIDEONOSMARIEES ='UPDATE_VIDEONOSMARIEES';
export const VIDEONOSMARIEES_ERROR ='VIDEONOSMARIEES_ERROR';
export const ADD_NOSMAREESCAROUSSEL ='ADD_NOSMAREESCAROUSSEL';
export const NOSMAREESCAROUSSEL_ERROR ='NOSMAREESCAROUSSEL_ERROR';
export const DELETE_NOSMAREESCAROUSSEL ='DELETE_NOSMAREESCAROUSSEL';
export const GET_NOSMAREESCAROUSSEL ='GET_NOSMAREESCAROUSSEL';
export const DELETE_ACTUALITES ='DELETE_ACTUALITES';
export const GET_ACTUALITES ='GET_ACTUALITES';
export const UPDATE_VIDEOACTUALITES ='UPDATE_VIDEOACTUALITES';
export const VIDEOACTUALITES_ERROR ='VIDEOACTUALITES_ERROR';
export const UPDATE_VIDEOFORMATION ='UPDATE_VIDEOFORMATION';
export const VIDEOFORMATION_ERROR ='VIDEOFORMATION_ERROR';
export const UPDATE_VIDEOEQUIPE ='UPDATE_VIDEOEQUIPE';
export const  VIDEOEQUIPE_ERROR ='VIDEOEQUIPE_ERROR';
export const UPDATE_CAROUSSELONGLERIE ='UPDATE_CAROUSSELONGLERIE';
export const CAROUSSELONGLERIE_ERROR ='CAROUSSELONGLERIE_ERROR';
export const CAROUSSELSOINSVISAGE_ERROR ='CAROUSSELSOINSVISAGE_ERROR';
export const UPDATE_CAROUSSELSOINSVISAGE ='UPDATE_CAROUSSELSOINSVISAGE';
export const UPDATE_VIDEOCOIFFURE ='UPDATE_VIDEOCOIFFURE';
export const VIDEOCOIFFURE_ERROR ='VIDEOCOIFFURE_ERROR';
export const UPDATE_MAKEUPCOIFFURE ='UPDATE_MAKEUPCOIFFURE';
export const MAKEUPCOIFFURE_ERROR ='MAKEUPCOIFFURE_ERROR';
export const UPDATE_SOINSCORPS ='UPDATE_SOINSCORPS';
export const SOINSCORPS_ERROR ='SOINSCORPS_ERROR';
export const UPDATE_CAROUSSELSOINSCORPS ='UPDATE_CAROUSSELSOINSCORPS';
export const CAROUSSELSOINSCORPS_ERROR ='CAROUSSELSOINSCORPS_ERROR';
export const UPDATE_CAROUSSELMAKEUPCOIFFURE ='UPDATE_CAROUSSELMAKEUPCOIFFURE';
export const CAROUSSELMAKEUPCOIFFURE_ERROR ='CAROUSSELMAKEUPCOIFFURE_ERROR';
export const ADD_FDL ='ADD_FDL';
export const FDL_ERROR = "FDL_ERROR";
export const DELETE_FDL ='DELETE_FDL';
export const GET_FDLS ='GET_FDLS';
export const UPDATE_VIDEOFDL ='UPDATE_VIDEOFDL';
export const VIDEOFDL_ERROR ="VIDEOFDL_ERROR";
import {
  UPDATE_CAROUSSELSOINSCORPS,
  CAROUSSELSOINSCORPS_ERROR
} from '../actions/types';

const initialState = {
  carousselsoinscorps: null,
  loading: true,
  error: {}
};
function carousselSoinsCorpsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    
    case   UPDATE_CAROUSSELSOINSCORPS:
      return {
        ...state,
        carousselsoinscorps: payload,
        loading: false
      };
    
    case   CAROUSSELSOINSCORPS_ERROR :
      return {
        ...state,
        error: payload,
        loading: false,
        carousselsoinscorps: null
      };
    
    default:
      return state;
  }
}

export default carousselSoinsCorpsReducer;

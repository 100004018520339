import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActualites ,deleteActualite } from '../../actions/actualites';
import { Link} from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const ListActualites = ({ 
  
  getActualites ,
  deleteActualite,
  actualites:{actualites}
}) => {

  useEffect(() => {
    getActualites();
  },[getActualites])
  const [page, setPage] = useState(1)
  
  const selectPageHandle = (selectedPage) => { // Pagination Logic
    if (selectedPage >= 1 &&
        selectedPage <= actualites.length / 1 &&
        selectedPage !== page) {
        setPage(selectedPage)
    }
}

  return (
    <div className='container-table2 '>
    <div className='main-table2'>
  <div className='userTable '>
  <h1 className='heading'>
      Actualités:
  </h1>
  <Link to='/actualitesform'>
  <button className='contactCTA contact '  type="button">ajouter Actualité</button>  
  </Link>
  
  <table className='table2'>
    <tbody>
      <tr>
          <th className='userAddress'>Title</th>
          
          <th className='userAction'>Delete</th>
      </tr>
      {
  actualites.length > 0 ?  actualites.slice(page * 5 - 5, page * 5).map((actualite, index) => {
    return (<tr >
      <td className='userAddress f-weight'>{actualite.titre}</td>
      
      <td className='contact' ><button className='contactCTA'
      onClick={()=>deleteActualite(actualite._id)} >Delete</button></td>

      
      
      
      
      
      
    </tr>)
  }) : <tr>
      <td colSpan="7" className="no-results">Il n'y a pas des actualites .</td>
    </tr>
}
      </tbody>
  </table>

  

  {/* JSX PArt */}
  {
      actualites.length > 0 && <div className='paginationactualites'>
          <div className='arrows' onClick={() => selectPageHandle(page - 1)}>
              <MdKeyboardArrowLeft size={25} />
          </div>
          <div className='pageNumbers'>
              {
                   [...Array(Math.ceil(actualites.length / 5))].map((n, i) => {
                      return <div  
                      className={`num ${page === i + 1 ? `numActive` : ''}`} 
                      onClick={() => selectPageHandle(i + 1)} >{i + 1}</div>
                  })
              }
          </div>
          <div className='arrows' onClick={() => selectPageHandle(page + 1)}>
              <MdKeyboardArrowRight size={25} />
          </div>
      </div>
  }
</div>
</div>
</div>
)
}

ListActualites.propTypes ={
  getActualites: PropTypes.func.isRequired,
  deleteActualite:PropTypes.func.isRequired,
  actualites: PropTypes.object.isRequired,
 }
 const mapStateToProps = (state) => ({
   actualites: state.actualites
 });
export default connect(mapStateToProps, { getActualites,deleteActualite }) (ListActualites)

import api from '../utils/api';
import { setAlert } from './alert';

import {  
  ADD_NOSMAREESCAROUSSEL,
  NOSMAREESCAROUSSEL_ERROR,
  DELETE_NOSMAREESCAROUSSEL,
  GET_NOSMAREESCAROUSSEL
} from './types';


export const  createNosMarieesCaroussel = (formData,navigate ) => async (dispatch) => {
  try {
  
    const res = await api.post('/nosMarieescaroussel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    dispatch({
      type: ADD_NOSMAREESCAROUSSEL,
      payload: res.data
    });

    dispatch(
      setAlert( 'article ajouté' , 'success')
    );
    navigate('/nosmariees');

    
  } catch (err) {
    dispatch({
      type: NOSMAREESCAROUSSEL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const getNosMarieesCaroussel = () => async (dispatch) => {
  try {
    const res = await api.get('/nosmarieescaroussel');
    dispatch({
      type: GET_NOSMAREESCAROUSSEL,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: NOSMAREESCAROUSSEL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const deleteNosMarieesCaroussel = (id) => async (dispatch) => {
  if (window.confirm('Are you sure? This can NOT be undone!')) {
  try {
    await api.delete(`/nosmarieescaroussel/${id}`);

    dispatch({
      type: DELETE_NOSMAREESCAROUSSEL,
      payload: id
    });

    dispatch(setAlert('Article supprimé', 'success'));
  } catch (err) {
    dispatch({
      type: NOSMAREESCAROUSSEL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}
};
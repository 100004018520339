import api from '../utils/api';
import { setAlert } from './alert';
import {
  
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAIL
  
 
} from './types';


// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get('/auth');

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};
// Login User
export const login = (email, password,navigate) => async (dispatch) => {
  const body = { email, password };

  try {
    const res = await api.post('/auth', body);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });
    navigate('/');

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// Change Password
export const changePassword = (currentPassword, newPassword, navigate) => async (
  dispatch
) => {
  const body = { currentPassword, newPassword };

  try {
    const res = await api.put('/auth/password', body);

    dispatch({
      type: PASSWORD_CHANGE_SUCCESS,
      payload: res.data,
    });

    dispatch(setAlert('Password updated successfully', 'success'));
    navigate('/home');
  } catch (err) {
    if (err.response && err.response.data && err.response.data.errors) {
      const errors = err.response.data.errors;
  
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    } else {
      console.error("An error occurred:", err);
    }
  
    dispatch({
      type: PASSWORD_CHANGE_FAIL,
    });
  }
}

// Logout
export const logout = () => ({ type: LOGOUT });
import {
  UPDATE_CAROUSSELMAKEUPCOIFFURE,
  CAROUSSELMAKEUPCOIFFURE_ERROR
} from '../actions/types';

const initialState = {
  carousselmakeupcoiffure: null,
  loading: true,
  error: {}
};
function carousselMakeupCoiffureReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    
    case   UPDATE_CAROUSSELMAKEUPCOIFFURE:
      return {
        ...state,
        carousselmakeupcoiffure: payload,
        loading: false
      };
    
    case   CAROUSSELMAKEUPCOIFFURE_ERROR :
      return {
        ...state,
        error: payload,
        loading: false,
        carousselmakeupcoiffure: null
      };
    
    default:
      return state;
  }
}

export default carousselMakeupCoiffureReducer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createVideoEquipe } from '../../actions/videoEquipe';
import { useNavigate,Link } from 'react-router-dom';

const VideoEquipe = ({createVideoEquipe}) => {
  const navigate = useNavigate();

  const [video, setVideo] = useState(null);
  const [text, setText] = useState('');

  async function handleOnSubmit(e) {
    e.preventDefault();
       
    const formData = new FormData();
    formData.append('text', text);
    if (video) {
      formData.append('video', video);
      
    }
    
    await createVideoEquipe(formData);
    setText('');
    setVideo(null);
  }

  return (
    <div>
      <div className='container-trainings'>
 <div className='main'>

 <div className="details">
 
     
   <div className="table">
     <div className="Header">
     
       <h2>Modifier l'interface equipe </h2>
       <small>* = required field</small>
     </div>
     <div className="internship">
     <form
   className="form" encType='multipart/form-data'  onSubmit={handleOnSubmit}>
     
                <div className='form-group'>
                <textarea
                    id="text"
                    name="text"
                    placeholder="* paragraphe equipe"
                    value={text}
                    onChange={e=>setText(e.target.value)}
                    required
                  />
                </div>



        <div className="form-group">
            <input
              type="file"
              placeholder="* video"
              name="video"  
              accept="video/*"
              id="video-upload"
              className='custom-file-input-resume'
              onChange={e=>setVideo(e.target.files[0])} 

              required         
            />                   
          </div>
        

        
       <input type="submit" className="internshipBtn" value="MODIFIER" />
      
     </form>
     </div>
   </div>
   </div>
 
</div>
</div>
    </div>
  )
}

VideoEquipe.propTypes = {
  createVideoEquipe:PropTypes.func.isRequired,
}

export default connect(null,{createVideoEquipe}) (VideoEquipe)

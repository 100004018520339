import {
  UPDATE_CAROUSSELSOINSVISAGE,
  CAROUSSELSOINSVISAGE_ERROR
} from '../actions/types';

const initialState = {
 carousselsoinsvisage: null,
  loading: true,
  error: {}
};
function carousselSoinsVisageReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    
    case   UPDATE_CAROUSSELSOINSVISAGE:
      return {
        ...state,
        carousselsoinsvisage: payload,
        loading: false
      };
    
    case   CAROUSSELSOINSVISAGE_ERROR :
      return {
        ...state,
        error: payload,
        loading: false,
        carousselsoinsvisage: null
      };
    
    default:
      return state;
  }
}

export default carousselSoinsVisageReducer;

import React,{ useState } from 'react'
import Coiffure from './Coiffure';
import CarousselSoinsVisage from './CarousselSoinsVisage';
import Ongleries from './Ongleries';
import SoinsVisages from './SoinsVisages';
import CarousselOnglerie from './CarousselOnglerie';
import VideoCoiffure from './VideoCoiffure';
import MakeupCoiffure from './MakeupCoiffure';
import SoinsCorps from './SoinsCorps';
import CarousselSoinsCorps from './CarousselSoinsCorps';
import CarousselMakeupCoiffure from './CarousselMakeupCoiffure';


const NosServices = () => {

  const [activeForm, setActiveForm] = useState('ongleries'); // Initial active form
  const handleFormChange = (formName) => {
    setActiveForm(formName);
  };

  const renderForm = () => {
    switch (activeForm) {
      case 'coiffure':
        return <Coiffure />;
      
      case 'videocoiffure':
        return < VideoCoiffure/>;
        case 'ongleries':
        return <Ongleries />;
      
        case 'soinsvisage':
        return < SoinsVisages/>;
        case 'carousselonglerie':
        return < CarousselOnglerie/>;
        case 'carousselsoinsvisage':
        return < CarousselSoinsVisage/>;
        case 'makeupcoiffure':
        return < MakeupCoiffure/>;
        case 'soinscorps':
        return < SoinsCorps/>;
        case 'carousselsoinscorps':
        return < CarousselSoinsCorps/>;
        case 'carousselmakeupcoiffure':
        return < CarousselMakeupCoiffure/>;
     
      default:
        return null;
    }
  };
  return (
    <div className='container-trainings'>
 <div className='main'>
 <div class="cardBox">
        <div class="card" className="card" onClick={() => handleFormChange('ongleries')}>
            <div class="numbers">Ongleries</div>
        </div>
        <div class="card" className="card" onClick={() => handleFormChange('carousselonglerie')}>
            <div class="numbers">Caroussel Ongleries</div>
        </div>
        
        
        <div class="card" className="card" onClick={() => handleFormChange('videocoiffure')}>
            <div class="numbers">Video Coiffure</div>
        </div>
        <div class="card" className="card" onClick={() => handleFormChange('coiffure')}>
            <div class="numbers">Coiffure</div>
        </div>
        
        <div class="card" className="card" onClick={() => handleFormChange('soinsvisage')}>
            <div class="numbers">Soins Visage</div>
        </div>
        <div class="card" className="card" onClick={() => handleFormChange('carousselsoinsvisage')}>
            <div class="numbers">Caroussel Soins Visage</div>
        </div>
        <div class="card" className="card" onClick={() => handleFormChange('soinscorps')}>
            <div class="numbers">Soins Corps</div>
        </div>
        <div class="card" className="card" onClick={() => handleFormChange('carousselsoinscorps')}>
            <div class="numbers">Caroussel Soins Corps</div>
        </div>
        <div class="card" className="card" onClick={() => handleFormChange('makeupcoiffure')}>
            <div class="numbers">Makeup & Coiffure</div>
        </div>
        <div class="card" className="card" onClick={() => handleFormChange('carousselmakeupcoiffure')}>
            <div class="numbers">Caroussel Makeup & Coiffure</div>
        </div>
        
        
      </div>
 <div className="details">
 
     
   
     
     {/* Render the active form */}
     {renderForm()}
   
   </div>
 
</div>
</div>
  )
}

export default NosServices

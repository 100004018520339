import api from '../utils/api';
import { setAlert } from './alert';

import {  
  UPDATE_VIDEONOSMARIEES,
  VIDEONOSMARIEES_ERROR
} from './types';


export const  createVideoNosMariees = (formData ) =>
async (dispatch) => {
  try {
  
    const res = await api.put('/videonosmariees', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    dispatch({
      type: UPDATE_VIDEONOSMARIEES,
      payload: res.data
    });

    dispatch(
      setAlert( 'Video Nos Mariees modifié' , 'success')
    );

    
  } catch (err) {
    dispatch({
      type:   VIDEONOSMARIEES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
import api from '../utils/api';
import { setAlert } from './alert';

import {  
  UPDATE_ACTUALITES,
  ACTUALITES_ERROR,
  DELETE_ACTUALITES,
  GET_ACTUALITES
} from './types';


export const  createActualites = (formData,navigate ) =>
async (dispatch) => {
  try {
    const res = await api.post('/actualites', formData);

    dispatch({
      type: UPDATE_ACTUALITES,
      payload: res.data
    });

    dispatch(
      setAlert( 'Actualité Ajouté' , 'success')
    );
    navigate('/actualites');

    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ACTUALITES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const deleteActualite = (id) => async (dispatch) => {
  if (window.confirm('Are you sure? This can NOT be undone!')) {
  try {
    await api.delete(`/actualites/${id}`);

    dispatch({
      type: DELETE_ACTUALITES,
      payload: id
    });

    dispatch(setAlert('Article supprimé', 'success'));
  } catch (err) {
    dispatch({
      type: ACTUALITES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}
};

export const getActualites = () => async (dispatch) => {
  try {
    const res = await api.get('/actualites');
    dispatch({
      type: GET_ACTUALITES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ACTUALITES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
import {
  UPDATE_VIDEOEQUIPE,
  VIDEOEQUIPE_ERROR
} from '../actions/types';

const initialState = {
  videoequipe: null,
  loading: true,
  error: {}
};
function videoEquipeReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    
    case UPDATE_VIDEOEQUIPE:
      return {
        ...state,
        videoequipe: payload,
        loading: false
      };
    
    case   VIDEOEQUIPE_ERROR
    :
      return {
        ...state,
        error: payload,
        loading: false,
        videoequipe: null
      };
    
    default:
      return state;
  }
}

export default videoEquipeReducer;

import {
  UPDATE_VIDEOCOIFFURE,
  VIDEOCOIFFURE_ERROR
} from '../actions/types';

const initialState = {
  videocoiffure: null,
  loading: true,
  error: {}
};
function videoCoiffureReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    
    case UPDATE_VIDEOCOIFFURE:
      return {
        ...state,
        videocoiffure: payload,
        loading: false
      };
    
    case   VIDEOCOIFFURE_ERROR
    :
      return {
        ...state,
        error: payload,
        loading: false,
        videocoiffure: null
      };
    
    default:
      return state;
  }
}

export default videoCoiffureReducer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createNosMarieesCaroussel  } from '../../actions/nosMarieesCaroussel';
import { useNavigate,Link } from 'react-router-dom';

const NosMarieesCarousselForm = ({createNosMarieesCaroussel}) => {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    titre: '',
    description: '',
    photos: null,
  });

  const handleFieldChange = (fieldName, value) => {
    if (fieldName === 'photos') {
      const photosArray = Array.from(value);
      setFormValues(prevValues => ({
        ...prevValues,
        [fieldName]: photosArray,
      }));
    } else {
      setFormValues(prevValues => ({
        ...prevValues,
        [fieldName]: value,
      }));
    }
  };

  async function handleOnSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    for (const fieldName in formValues) {
      if (fieldName.startsWith('photos')) {
        if (formValues[fieldName]) {
          for (let i = 0; i < formValues[fieldName].length; i++) {
            formData.append(fieldName, formValues[fieldName][i]);
          }
        }
      } else {
        formData.append(fieldName, formValues[fieldName]);
      }
    }

    await createNosMarieesCaroussel(formData, navigate);

    // Clear form values
    setFormValues({
      titre: '',
      description: '',
      photos: null,
    });
  }

  return (
    <div>
      <div className='container-trainings'>
 <div className='main'>

 <div className="details">
 
     
   <div className="table">
     <div className="Header">
     <Link to='/nosmariees'>
  <button className='contactCTA contact '  type="button">RETOURNER</button>  
  </Link>
       <h2>ajouter les détails de votre article </h2>
       <small>* = required field</small>
     </div>
     <div className="internship">
     <form
   className="form" encType='multipart/form-data'  onSubmit={handleOnSubmit}>
     <div className='form-group'>
                  <input
                    type="text"
                    placeholder="* Titre "
                    name="titre"
                    value={formValues.titre}
                    onChange={e => handleFieldChange('titre', e.target.value)}
                    required
                  />
                </div>
                <div className='form-group'>
                <textarea
                    id="description"
                    name="description"
                    placeholder="* description"
                    value={formValues.description}
                    onChange={e => handleFieldChange('description', e.target.value)}
                    required
                  />
                </div>
        

          <div className="form-group">
        <label htmlFor="photos-upload">Upload photos:</label>
            <input
              type="file"
              placeholder="* photos"
              name="photos"  
              accept="images/*"
              id="photos-upload"
              className='custom-file-input-resume'
              onChange={e => handleFieldChange('photos', e.target.files)}
              multiple

              required         
            />                   
          </div>
          

        
       <input type="submit" className="internshipBtn" value="AJOUTER" />
      
     </form>
     </div>
   </div>
   </div>
 
</div>
</div>
    </div>
  )
}

NosMarieesCarousselForm.propTypes = {
  createNosMarieesCaroussel:PropTypes.func.isRequired,
}

export default connect(null,{createNosMarieesCaroussel}) (NosMarieesCarousselForm)

import {
  ADD_NOSMAREESCAROUSSEL,
  NOSMAREESCAROUSSEL_ERROR,
  DELETE_NOSMAREESCAROUSSEL,
  GET_NOSMAREESCAROUSSEL
} from '../actions/types';

const initialState = {
  nosmarieescaroussels:[],
  nosmarieescaroussel: null,
  loading: true,
  error: {}
};
function nosMarieesCarousselReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    
    case GET_NOSMAREESCAROUSSEL:
      return {
        ...state,
        nosmarieescaroussels: payload ?? [],
        loading: false
      };
      case DELETE_NOSMAREESCAROUSSEL:
      return {
        ...state,
        nosmarieescaroussels: state.nosmarieescaroussels.filter((nosmarieescaroussel) => nosmarieescaroussel._id !== payload),
        loading: false,
        error: null,
      };
    
    case   NOSMAREESCAROUSSEL_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        nosmarieescaroussel: []
      };
      case ADD_NOSMAREESCAROUSSEL:
      return {
        ...state,
        nosmarieescaroussels: [payload, ...state.nosmarieescaroussels],
        loading: false
      };
    
    default:
      return state;
  }
}

export default nosMarieesCarousselReducer;

import {
  UPDATE_VIDEOFDL,
  VIDEOFDL_ERROR
} from '../actions/types';

const initialState = {
  videofdl: null,
  loading: true,
  error: {}
};
function videoFdlReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    
    case UPDATE_VIDEOFDL:
      return {
        ...state,
        videofdl: payload,
        loading: false
      };
    
    case   VIDEOFDL_ERROR
    :
      return {
        ...state,
        error: payload,
        loading: false,
        videofdl: null
      };
    
    default:
      return state;
  }
}

export default videoFdlReducer;

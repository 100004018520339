import {
  ADD_FDL,
  FDL_ERROR,
  DELETE_FDL,
  GET_FDLS
} from '../actions/types';

const initialState = {
  fdls:[],
  fdl: null,
  loading: true,
  error: {}
};
function fdlBeautyShopReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    
    case GET_FDLS:
      return {
        ...state,
        fdls: payload,
        loading: false
      };
      case DELETE_FDL:
      return {
        ...state,
        fdls: state.fdls.filter((fdl) => fdl._id !== payload),
        loading: false,
        error: null,
      };
    
    case   FDL_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        fdl: null
      };
      case ADD_FDL:
      return {
        ...state,
        fdls: [payload, ...state.fdls],
        loading: false
      };
    
    default:
      return state;
  }
}

export default fdlBeautyShopReducer;

import axios from 'axios';
import store from '../store';
import { LOGOUT } from '../actions/types';

// Create an instance of axios
/*let baseURL = process.env.REACT_APP_API_BASE_URL
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    baseURL = '/api'
}
*/
let baseURL = 'https://api.fleurdelys.com.tn/api' 
const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});
/*
  NOTE: intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated.
 logout the user if the token has expired
*/

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      store.dispatch({ type: LOGOUT });
    }
    return Promise.reject(err);
  }
);

export default api;
